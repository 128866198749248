<script>
import ModalCommonContentContainer from "../../../components/Modals/ModalCommonContentContainer.vue";
import {cropImage} from "../../../utils/utils";
import AddFolderModalContent from "./AddFolderModalContent.vue";

export default {
  name: "AddKeywordsToFolderModal",
  components: {AddFolderModalContent, ModalCommonContentContainer},
  props: {
    countryCode: {
      type: String,
      required: true,
    },
    keywords: {
      type: Array,
      required: true,
    },

    handleSave: {
      type: Function,
      required: true,
    },
    handleSaveFolder: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },

    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mode: 'existing',
      showAllKeywords: false,
    }
  },
  methods: {
    save() {
      if (this.mode === 'new') {
        this.handleSaveFolder(this.$refs.form.folder);
      }

      this.handleSave();
    },
  }
}
</script>

<template>
  <ModalCommonContentContainer>
    <template v-slot:heading>
      Adding Keywords to the Folder
    </template>
    <template v-slot:content>
      <div class="addKeywordsToFolderContainer">

        <div class="youChose">You chose {{keywords.length}} keywords:</div>
        <div class="mt-8 display-flex flex-wrap keywordsContainer">
          <template v-for="(keyword, index) in keywords">
            <div class="keyword_item" v-show="index < 5 || showAllKeywords">{{keyword}}</div>
          </template>
          <template v-if="keywords.length > 5">
            <div class="moreLess" v-if="!showAllKeywords" @click="showAllKeywords = true">+ {{keywords.length - 5}} More</div>
            <div class="moreLess" v-else @click="showAllKeywords = false">- {{keywords.length - 5}} Less</div>
          </template>
        </div>

        <div @click="mode = 'existing'" class="radioLabel display-flex mt-26"><div class="radio mr-8" :class="{'active': mode === 'existing'}"></div>Add to existing Folders. Select one or more Folder</div>
        <div class="mt-16">
          1
        </div>
        <div @click="mode = 'new'" class="radioLabel display-flex mt-24"><div class="radio mr-8" :class="{'active': mode === 'new'}"></div>Add to new Folder</div>
        <div v-show="mode === 'new'" class="mt-16">
          <AddFolderModalContent ref="form" :app="app"/>
        </div>

        <div class="mt-31 display-flex">
          <div class="common-btn-purple mr-12" @click="save">Add to Folder</div>
          <div class="common-btn-purple-transparent" @click="handleCancel">Cancel</div>
        </div>
      </div>
    </template>
  </ModalCommonContentContainer>
</template>

<style scoped lang="scss">
.addKeywordsToFolderContainer {
  color: var(--Neutral-800, #505660);
  user-select: none;

  .keywordsContainer {
    max-height: 300px;
    overflow-y: auto;
  }

  .moreLess {
    margin-left: 6px;
    cursor: pointer;
    color: var(--Primary-Text, #3942DC);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-decoration-line: underline;
  }

  .youChose {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .keyword_item {
    padding: 4px 8px;
    border: 1px solid var(--Neutral-400, #E1E8F2);
    background: var(--Neutral-400, #E1E8F2);
    border-radius: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    width: max-content;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--primary-default);

    &.active {
      background: radial-gradient(circle, var(--primary-default) 0%, var(--primary-default) 40%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }
  }

  .radioLabel {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
  }

  .common-btn-purple {
    width: 185px;
    max-width: 185px;
    height: 40px;
  }
  .common-btn-purple-transparent {
    width: 158px;
    max-width: 185px;
    height: 40px;
  }
}
</style>