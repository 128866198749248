<script>
import {cropImage} from "../../../utils/utils";

export default {
  name: "AddFolderModalContent",
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      folder: {
        name: '',
        description: '',
      },
    }
  },
  methods: {
    cropImage,
  }
}
</script>

<template>
  <div class="addFolderContainer">
    <div class="display-flex forApp mb-25">
      <span class="mr-12">for the App: </span>
      <img class="mr-8" :src="cropImage(app.logo, '24x24', app.store.key)" :alt="app.title">
      <span class="mr-8">{{app.title}}</span>
      <img v-if="app.store.key === 'APP_STORE'"
           src="@/assets/images/icons/app_store.png"
           alt="Store icon"
           class="store-icon"
           width="24"
           height="24">
      <img v-else
           src="@/assets/images/icons/google_play.png"
           alt="Store icon"
           class="store-icon"
           width="24"
           height="24">
    </div>
    <div class="mt-16 createNote">
      Create a Folder to save your chosen keywords
    </div>
    <div class="mt-16">
      <label for="newFolderName" class="mb-8">Name the folder</label>
      <div class="nameNote mb-8">👉 Keep it short - one-two words are the best</div>
      <input type="text" id="newFolderName" placeholder="Name" v-model="folder.name">
    </div>
    <div class="mt-16">
      <label for="newFolderDesc">Add description (optionally)</label>
      <div class="mb-8"></div>
      <textarea id="newFolderDesc" placeholder="Description" v-model="folder.description"></textarea>
    </div>
  </div>
</template>

<style scoped lang="scss">
.addFolderContainer {
  color: var(--Neutral-800, #505660);
  user-select: none;

  .forApp {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  label[for="newFolderName"] {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  label[for="newFolderDesc"] {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .createNote {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .nameNote {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  input, textarea {
    &:focus {
      outline: none;
    }
    border: 1px solid var(--Neutral-400, #E1E8F2);
    border-radius: 4px;
    padding: 8px 16px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  input {
    width: 50%;
    height: 44px;
  }
  textarea {
    width: 100%;
    min-width: 490px;
    height: 62px;
    resize: none;
  }
  .common-btn-purple {
    width: 185px;
    max-width: 185px;
    height: 40px;
  }
  .common-btn-purple-transparent {
    width: 158px;
    max-width: 185px;
    height: 40px;
  }
}

</style>