<script>
import ModalCommonContentContainer from "../../../components/Modals/ModalCommonContentContainer.vue";
import {cropImage} from "../../../utils/utils";
import AddFolderModalContent from "./AddFolderModalContent.vue";

export default {
  name: "AddFolderModal",
  components: {AddFolderModalContent, ModalCommonContentContainer},
  props: {
    app: {
      type: Object,
      required: true,
    },
    handleSave: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    cropImage,
    save() {
      this.handleSave(
          this.$refs.form.folder,
      );
    },
  }
}
</script>

<template>
<ModalCommonContentContainer>
  <template v-slot:heading>
    Adding a Folder
  </template>
  <template v-slot:content>
    <AddFolderModalContent ref="form" :app="app"/>

    <div class="mt-31 display-flex">
      <div class="common-btn-purple mr-12" @click="save">Save Folder</div>
      <div class="common-btn-purple-transparent" @click="handleCancel">Cancel</div>
    </div>
  </template>
</ModalCommonContentContainer>
</template>

<style scoped lang="scss">
</style>