<template>
  <div class="redefine-keywords-language">
    <custom-modal
        :opened="showModal"
        :max-width="720"
        class-name="redefine-keywords-language-modal"
        @closeModal="close"
    >
      <div class="redefine-keywords-language-wrap">
        <div class="modal-message textarea-block">
          Redefining Keywords Language
        </div>
        <div class="keywords-block">
          <span>You choose {{ keywordsCount }} keywords: </span>
          <div class="grouped-keywords" v-for="(group, code) in groupedKeywords" :key="code">
            <span>{{ group.name  + ' (' + group.keywords.length + '):' }}</span>
            <custom-chips
                v-for="(chip, index) in group.keywords"
                :key="index"
                :selectable="true"
                :is-selected="true"
                :show-tooltip="false"
                :is-frozen="true"
            >{{ chip.keyword }}
            </custom-chips>
          </div>
        </div>
        <div class="search-input-block">
          <div class="modal-message textarea-block">
            Select new Language:
          </div>
          <basic-search-input
              placeholder="Search language"
              :clear-input="searchInput"
              @search-input-changed="searchInputChanged"
              :hide-results-block="true">
          </basic-search-input>
        </div>
        <div class="sort-block">
          <div class="label">Sort by:</div>
          <div>
            <div class="mode-switcher">
              <div :class="{active: sortBy === 'popularity'}" @click="changeLanguages('popularity')">
                Popular First
              </div>
              <div :class="{active: sortBy === 'alphabet'}" @click="changeLanguages('alphabet')">
                By Alphabet
              </div>
            </div>
          </div>
        </div>
        <div class="options-block">
          <div class="languages" v-for="language in localLanguages" :key="language.code_iso639">
            <radio-button
                @click="redefineLanguage(language)"
                :active="redefinedLanguage
                  ? isRedefinedLanguage(language.code_iso639)
                  : isKeywordsLanguage(language.code_iso639)"
            >
              {{  language.name  }}
              <span v-if="isKeywordsLanguage(language.code_iso639)">
              currently detected
            </span>
            </radio-button>
          </div>
        </div>
        <div class="buttons-block">
          <base-button
              :disabled="!redefinedLanguage"
              width="199px"
              height="36px"
              class="btn-standard button-save"
              border-radius="4px"
              font-size="16px"
              @click="save">
            Define as {{ redefinedLanguage ? '"' + redefinedLanguage.name + '"' : ''}}
          </base-button>
          <base-button
              width="100px"
              height="36px"
              class="btn-standard button-cancel"
              border-radius="4px"
              font-size="16px"
              @click="() => close()">
            Cancel
          </base-button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import RadioButton from "@/components/UI/RadioButton/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import BaseButton from "@/components/UI/BaseButton/index.vue";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import {httpRequest} from "@/api";

export default {
  name: 'RedefineKeywordsLanguageModal',
  emits: ['modal-closed'],
  components: {
    CustomChips,
    RadioButton,
    CustomDropdown,
    BasicSearchInput,
    SvgIcon,
    BaseButton,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    keywords: {
      type: Object,
      required: true,
    },
    languages: {
      type: Object,
      required: true,
    },
    currentApp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupedKeywords: {},
      searchInput: '',
      sortBy: 'popularity',
      redefinedLanguage: null,
      localLanguages: [],
      languagesByPopularity: [],
      languagesByAlphabet: [],
    };
  },
  methods: {
    close(reFetchKeywordsData = false) {
      this.searchInputChanged('');
      this.sortBy = 'popularity';
      this.localLanguages = this.languagesByPopularity;
      this.redefinedLanguage = null;
      this.$emit('modal-closed', reFetchKeywordsData);
    },
    searchInputChanged(search) {
      this.localLanguages = search
          ? this.localLanguages.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
          : this.getLanguages(this.sortBy);

      this.searchInput = search;
    },
    changeLanguages(sortBy) {
      this.searchInputChanged('');
      this.localLanguages = this.getLanguages(sortBy)
      this.sortBy = sortBy;
    },
    getLanguages(sortBy) {
      return sortBy === 'popularity' ? this.languagesByPopularity : this.languagesByAlphabet;
    },
    isKeywordsLanguage(languageCode) {
      return this.groupedKeywords.hasOwnProperty(languageCode);
    },
    redefineLanguage(language) {
      this.redefinedLanguage = language;
    },
    isRedefinedLanguage(languageCode) {
      return this.redefinedLanguage.code_iso639 === languageCode;
    },
    ungroupKeywords() {
      return Object.keys(this.groupedKeywords).flatMap(
          lang => this.groupedKeywords[lang].keywords.map(item => ({id: item.id, language: lang}))
      );
    },
    async save() {
      await httpRequest(
          'POST',
          process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.REDEFINE_KEYWORDS_LANGUAGE,
          {
              keywords: this.ungroupKeywords(),
              redefined_language: this.redefinedLanguage.code_iso639,
        },
      );
      this.close(true);
    }
  },
  watch: {
    keywords() {
      if (this.keywords) {
        this.groupedKeywords = this.keywords.reduce((result, item) => {
          const {code, name} = item.language;

          if (!result[code]) {
            result[code] = {name, keywords: []};
          }

          result[code].keywords.push({id: item.saved_keyword_id, keyword: item.keyword});

          return result;
        }, {});
      }
    },
    languages() {
      if (this.languages) {
        this.languagesByPopularity = Object.values(this.languages);
        this.languagesByAlphabet = this.languagesByPopularity.slice().sort((a, b) => a.name.localeCompare(b.name));
        this.localLanguages = this.languagesByPopularity;
      }
    },
  },
  computed: {
    keywordsCount() {
      return Object.keys(this.keywords).length;
    },
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>