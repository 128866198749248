<template>
  <div class="modal-message">
    <div class="modal-heading medium-heading">
      <h3>Add Keywords</h3>
    </div>
    <textarea-with-chips :chips-array="addedKeywordsArray"
                         :source-array="trackedKeywords"
                         @input-changed="keywordsChipsInputChanged"
                         @chip-added="addChips($event, 'addedKeywordsArray', 'suggestedKeywords')"
                         @chip-deleted="deleteChips($event, 'addedKeywordsArray', 'suggestedKeywords')"></textarea-with-chips>

    <div class="modal-btn-block">
      <base-button mode="outline"
                   height="40px"
                   width="140px"
                   border-radius="4px"
                   :disabled="addedKeywordsArray?.length < 1"
                   @click="deleteAllAddedKeywords('addedKeywordsArray', 'suggestedKeywords')">Delete All
      </base-button>

      <base-button height="40px"
                   width="140px"
                   border-radius="4px"
                   @click="saveAddedKeywordsClicked">Save
      </base-button>
    </div>

    <div class="suggested-keywords-block"
         v-if="recommendedSuggestedKeywords?.length > 0">
      <div class="modal-heading medium-heading">
        <h3>Pay attention to these keywords</h3>
      </div>

      <div class="suggested-keywords">
        <custom-chips
         @chip-deleted="deleteSuggestedChips(chip)"
         @chip-added="addChips(chip, 'addedKeywordsArray', 'recommendedSuggestedKeywords')"
         v-for="(chip, idx) in recommendedSuggestedKeywords"
         :selectable="true"
         :is-selected="chip.selected"
         :is-frozen="chip.frozen"
         :key="idx"
         :amount="+chip?.sap">{{ chip.keyword }}
        </custom-chips>
      </div>
    </div>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import CustomChips from "@/components/Forms/CustomChips";
import CustomTextareaWithChips from "@/components/Forms/CustomTextareaWithChips/index.vue";

export default {
  name: "AddKeywordsModal",
  props: {
    currentCountryCode: {
      type: String,
    },
    currentApp: {
      type: Object,
    },
    trackedKeywords: {
      type: Array,
    }
  },
  components: {
    'textarea-with-chips': CustomTextareaWithChips,
    'custom-chips': CustomChips,
  },
  emits: ['save-keywords'],
  data() {
    return {
      keywordsChipsInput: '',
      recommendedSuggestedKeywordsAddedAll: false,
      recommendedSuggestedKeywords: [],
      suggestedKeywords: [],
      addedKeywordsArray: [],
    }
  },
  async mounted() {
    await this.fetchRecommendedSuggestKeywords();
  },
  methods: {
    async fetchRecommendedSuggestKeywords() {
      this.recommendedSuggestedKeywordsAddedAll = false;
      this.recommendedSuggestedKeywords = [];
      this.modalDataLoader = false;
      const urlQuery = '?app_id=' + this.currentApp.id + '&country_code=' + this.currentCountryCode + '&sort_by=sap&size=10&skip=0';

      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.recommendedKeywords.RECOMMENDED_KEYWORDS + urlQuery);
      let selected = 0;
      const suggestedKeywordsArray = result?.list.map(suggestion => {
        if (suggestion.selected) {
          selected++;
        }
        return {
          ...suggestion,
          keyword: suggestion?.keyword?.term,
          name: suggestion?.keyword?.term,
          frozen: suggestion.selected,
        };
      });

      this.recommendedSuggestedKeywords = suggestedKeywordsArray.filter(item => item?.sap > 0);
      this.recommendedSuggestedKeywordsAddedAll = selected === this.recommendedSuggestedKeywords?.length;
      this.modalDataLoader = true;
    },
    keywordsChipsInputChanged(e) {
      this.keywordsChipsInput = e;
    },
    deleteChips(chip) {
      const selectedChipsIdx = this.addedKeywordsArray.findIndex(keyword => keyword?.name === chip?.name);
      const selectedSuggestedChipsIdx = this.recommendedSuggestedKeywords.findIndex(keyword => keyword?.name === chip?.name);
      if (selectedChipsIdx >= 0) {
        this.addedKeywordsArray.splice(selectedChipsIdx, 1);
      }

      if (selectedSuggestedChipsIdx >= 0) {
        const tempChipsArray = [...this.recommendedSuggestedKeywords];
        tempChipsArray[selectedSuggestedChipsIdx].selected = false;
        this.recommendedSuggestedKeywords = [...tempChipsArray];
      }
    },
    deleteSuggestedChips(chip) {
      this.deleteChips(chip, 'addedAutoSuggestedKeywordsArray', 'autoSuggestedKeywords');
    },
    deleteAllAddedKeywords(textAreaArray) {
      this[textAreaArray] = [];
      const tempChipsArray = this.addedKeywordsArray.map(item => {
        return {
          ...item,
          selected: false
        }
      });
      this.addedKeywordsArray = [...tempChipsArray];

      const tempRecommended = [...this.recommendedSuggestedKeywords];
      tempRecommended.forEach(item => {
        item.selected = false;
      })
      this.recommendedSuggestedKeywords = [...tempRecommended];
      this.keywordsChipsInput = '';
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
    },
    addChips(chip, textAreaArray, suggestedArray) {
      this[textAreaArray].push({
        name: chip?.name ?? chip?.keyword
      });
      const selectedChipsIdx = this[suggestedArray].findIndex(keyword => keyword.name === chip.name);
      if (selectedChipsIdx >= 0) {
        const tempChipsArray = [...this[suggestedArray]];
        tempChipsArray[selectedChipsIdx].selected = true;
        this[suggestedArray] = [...tempChipsArray];
      }
    },
    async saveAddedKeywordsClicked() {
      this.keywordsLoaded = false;

      if (this.keywordsChipsInput.length > 0 && !this.addedKeywordsArray.some(e => e.name === this.keywordsChipsInput)) {
        this.addedKeywordsArray.push(this.keywordsChipsInput);
      }

      let addedKeywordsArr = [];
      this.addedKeywordsArray?.forEach(item => {
        if (item?.name) {
          addedKeywordsArr.push(item?.name);
        }
      });

      if (this.keywordsChipsInput !== '') {
        addedKeywordsArr.push(this.keywordsChipsInput);
      }

      this.$emit('save-keywords', addedKeywordsArr);

      this.addedKeywordsArray = [];
      this.keywordsChipsInput = '';
      this.keywordsLoaded = true;

    },
  },
}
</script>

<style scoped>

</style>